import { Button, Icon } from '@blueprintjs/core';
import { useObserver } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import DataSyncOdpObjectsStore from '../../../stores/DataSyncOdpObjectsStore';
import { DataSyncItem, OptimizelyProduct, SyncStrategy } from '../../../types/DataSyncItem';
import styles from './DataSyncForm.module.sass';
import FieldMappingsComponent from './FormSections/FieldMappingsComponent';
import DataSyncFormSource from './FormSections/DataSyncFormSource';
import DataSyncFormDestination from './FormSections/DataSyncFormDestination';
import { IconNames } from '@blueprintjs/icons';
import DataSyncScheduleSection from './FormSections/DataSyncScheduleSection';
import DataSyncConfigSection from './FormSections/DataSyncConfigSection';
import { ValidateHandle } from './FormSections/ValidateHandle';
import MainContainer from '../MainContainer';
import { directoryStore } from '../../../stores/DirectoryStore';
import DataSyncCmpObjectsStore from '../../../stores/DataSyncCmpObjectsStore';
import { DataSyncObjectsStore } from '../../../stores/DataSyncObjectsStore';
import { DataSyncStrategyProvider } from '../DataSyncStrategyProvider';
import DataSyncCgObjectsStore from '../../../stores/DataSyncCgObjectsStore';
import DataSyncHubAppObjectsStore from '../../../stores/DataSyncHubAppObjectsStore';
export interface DataSyncCreateProps {
  mode: 'create' | 'edit';
  onSubmit: (syncObj: DataSyncItem) => void;
  onCancel: () => void;
  initialValues: DataSyncItem;
}

const DataSyncForm = ({onSubmit, onCancel, initialValues}: DataSyncCreateProps) => {
  const [dataSync, setDataSync] = useState<DataSyncItem>({...initialValues});

  const [dataSyncSourceObjectsStore, setDataSyncSourceObjectsStore] = useState<DataSyncObjectsStore | null>(null);
  const [dataSyncDestinationObjectsStore, setDataSyncDestinationObjectsStore] = useState<DataSyncObjectsStore
    | null>(null);
  const [dataSyncOdpObjectsStore, setDataSyncOdpObjectsStore] = useState<DataSyncOdpObjectsStore | null>(null);
  const [dataSyncCmpObjectsStore, setDataSyncCmpObjectsStore] = useState<DataSyncCmpObjectsStore | null>(null);
  const [dataSyncCgObjectsStore, setDataSyncCgObjectsStore] = useState<DataSyncCgObjectsStore | null>(null);
  const [dataSyncHubAppObjectsStore, setDataSyncHubAppObjectsStore] = useState<DataSyncHubAppObjectsStore | null>(null);

  useEffect(() => {
    if (dataSync?.source?.product) decideDataSyncSourceObjectsStore(dataSync.source.product);
    if (dataSync?.destination?.product) decideDataSyncDestinationObjectsStore(dataSync.destination.product);
  }, []);

  const configRef = useRef<ValidateHandle>(null);
  const sourceRef = useRef<ValidateHandle>(null);
  const destinationRef = useRef<ValidateHandle>(null);
  const mappingsRef = useRef<ValidateHandle>(null);
  const scheduleRef = useRef<ValidateHandle>(null);

  const isValid = () => {
    const isConfigValid = configRef.current?.validate() ?? false;
    const isSourceValid = sourceRef.current?.validate() ?? false;
    const isDestinationValid = destinationRef.current?.validate() ?? false;
    const isMappingsValid = mappingsRef.current?.validate() ?? false;
    const isScheduleValid = scheduleRef.current?.validate() ?? false;

    return isConfigValid && isSourceValid && isDestinationValid && isMappingsValid &&
      (getStrategy() === SyncStrategy.WEBHOOK || isScheduleValid);
  };

  const getStrategy = () => {
    return DataSyncStrategyProvider.getStrategy(dataSync);
  };

  const decideDataSyncSourceObjectsStore = (product: OptimizelyProduct) => {
    let objectsStoreRef;
    if (product === OptimizelyProduct.CMP) {
      objectsStoreRef = dataSyncCmpObjectsStore;
      if (!objectsStoreRef) {
        objectsStoreRef = new DataSyncCmpObjectsStore();
        setDataSyncCmpObjectsStore(objectsStoreRef);
      }
    } else {
      objectsStoreRef = dataSyncOdpObjectsStore;
      if (!objectsStoreRef) {
        objectsStoreRef = new DataSyncOdpObjectsStore();
        setDataSyncOdpObjectsStore(objectsStoreRef);
      }
    }
    setDataSyncSourceObjectsStore(objectsStoreRef);
  };

  const decideDataSyncDestinationObjectsStore = (product: OptimizelyProduct) => {
    let objectsStoreRef;
    if (product === OptimizelyProduct.CGR) {
      objectsStoreRef = dataSyncCgObjectsStore;
      if (!objectsStoreRef) {
        objectsStoreRef = new DataSyncCgObjectsStore();
        setDataSyncCgObjectsStore(objectsStoreRef);
      }
    } else {
      objectsStoreRef = dataSyncHubAppObjectsStore;
      if (!objectsStoreRef) {
        objectsStoreRef = new DataSyncHubAppObjectsStore();
        setDataSyncHubAppObjectsStore(objectsStoreRef);
      }
    }
    setDataSyncDestinationObjectsStore(objectsStoreRef);
  };

  const actionButtons = (
    <div className={styles.actionButtons}>
      <Button
        text="Cancel"
        onClick={onCancel}
      />
      <Button
        text="Save"
        intent="primary"
        onClick={() => {
          if (isValid()) {
            const updatedDataSync = {
              ...dataSync,
              tracker_id: directoryStore.trackerId,
              interval: getStrategy() === SyncStrategy.SCHEDULED ? dataSync.interval : undefined
            };
            onSubmit(updatedDataSync);
          }
        }}
      />
    </div>
  );

  return useObserver(() => (
    <MainContainer title="To create a new sync, define a source and destination. Then setup field mapping"
                   headerActions={actionButtons}>
      <div className={styles.container}>
        <DataSyncConfigSection
          value={{name: dataSync.name}} ref={configRef}
          onChange={(obj) => {
            dataSync.name = obj.name;
            setDataSync({...dataSync});
          }}/>

        <div className={styles.row}>
          <DataSyncFormSource
            initialValue={dataSync.source} ref={sourceRef}
            dataSyncObjectsStore={dataSyncSourceObjectsStore}
            onChange={(value) => {
              dataSync.source = value;
              setDataSync({...dataSync});
              decideDataSyncSourceObjectsStore(value.product);
            }}
          />
          <div className={styles.arrowRight}>
            <Icon icon={IconNames.ARROW_RIGHT}/>
          </div>

          <DataSyncFormDestination
            initialValue={dataSync.destination} ref={destinationRef}
            dataSyncObjectsStore={dataSyncDestinationObjectsStore}
            onChange={(value) => {
              dataSync.destination = value;
              setDataSync({...dataSync});
              decideDataSyncDestinationObjectsStore(value.product);
            }}
          />
        </div>

        <div className={styles.row}>
          <FieldMappingsComponent
            ref={mappingsRef}
            fieldMappings={dataSync.field_mappings}
            sourceFields={dataSyncSourceObjectsStore?.getObjectFields(dataSync.source.object.object_name) || []}
            destinationFields={dataSyncDestinationObjectsStore?.getObjectFields(dataSync.destination.object.object_name)
              || []}
            onChange={(newFields) => {
              setDataSync({...dataSync, field_mappings: newFields});
            }}
          />
        </div>

        {getStrategy() === SyncStrategy.SCHEDULED && <div className={styles.row}>
          <DataSyncScheduleSection
            value={dataSync.interval} ref={scheduleRef}
            onChange={(value: string) => {
              dataSync.interval = value;
              setDataSync({...dataSync});
            }}/>
        </div>}
      </div>
    </MainContainer>
  ));
};

export default DataSyncForm;
