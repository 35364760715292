import { SelectItem } from '@zaiusinc/hera';
import { observable, reaction, action } from 'mobx';
import { ZipApi } from '../lib/ZipApi';
import { directoryStore } from './DirectoryStore';
import { OptimizelyProduct, ProductInstances } from '../types/DataSyncItem';
import { HubApp } from '../types/DataSyncItem';
import { AppConstants } from '../types/AppConstants';

export default class DataSyncProductsStore {
  @observable public loading = false;

  @observable private _productInstances: ProductInstances[] = [];
  @observable private _hubApps: HubApp[] = [];

  private DESTINATION_PRODUCTS_MAP = {
    'CGR': {
      name: 'Content Graph',
      icon: '/assets/content_graph.svg'
    },
    'HUB_APP': {
      name: 'Hub App',
      icon: '/assets/data_platform.svg'
    }
  };

  private SOURCE_PRODUCTS = [
    {
      display_name: 'Content Marketing Platform',
      name: 'CMP',
      icon: '/assets/cmp.svg'
    },
    {
      display_name: 'HUB',
      name: 'HUB',
      icon: '/assets/data_platform.svg'
    }
  ];

  public constructor() {
    reaction(() => directoryStore.trackerId, () => {
      if (directoryStore.trackerId) {
        this.fetch();
      }
    }, {fireImmediately: true});
  }

  @action
  public async fetch() {
    try {
      this.loading = true;
      this._productInstances = [];
      this._hubApps = [];
      if (directoryStore.trackerId) {
        this._productInstances = await ZipApi.listProductInstances(directoryStore.trackerId);
        const appInstallationsResponse = await ZipApi.listAppInstalls(directoryStore.trackerId,
          AppConstants.AppFeature.DESTINATION_APP);
        appInstallationsResponse.installations?.forEach(async (installation) => {
          const appResponse = await ZipApi.fetchAppVersion(installation.app_id, installation.version);
          const hubApp = {'name': appResponse.app_version.metadata.display_name,
            'icon_url': appResponse.app_version.metadata.icon_url,
            'app_id': installation.app_id, 'version': installation.version } as HubApp;
          this._hubApps.push(hubApp);
        });
      }
    } finally {
      this.loading = false;
    }
  }

  public getDestinationProductList(): Array<{ text: string; value: string; iconPath: string }> {
    const products = this._productInstances
      .filter((product) => this.DESTINATION_PRODUCTS_MAP[product.product])
      .map((product) => {
        return {
          text: this.DESTINATION_PRODUCTS_MAP[product.product].name || product.product,
          value: product.product,
          iconPath: this.DESTINATION_PRODUCTS_MAP[product.product].icon || '',
        };
    });
    products.push({
      text: this.DESTINATION_PRODUCTS_MAP.HUB_APP.name,
      value: OptimizelyProduct.HUB_APP,
      iconPath: this.DESTINATION_PRODUCTS_MAP.HUB_APP.icon,
    });
    return products;

  }

  public getSourceProductList(): Array<{ text: string; value: string; iconPath: string }> {
    return this.SOURCE_PRODUCTS.map((product) => {
      return {
        text: product.display_name,
        value: product.name,
        iconPath: product.icon
      };
    });
  }

  public getInstanceList(productName: string): Array<{ text: string; value: string; iconPath?: string }> {
    if (productName === OptimizelyProduct.HUB_APP) {
      return this._hubApps.map((app) => {
        return {
          text: app.name,
          value: app.app_id,
          iconPath: app.icon_url
        };
      });
    } else {
      const product = this._productInstances.find((obj) => obj.product === productName);
      if (!product) {
        return [];
      }
      return product.instances.map((instance) => {
        return {
          text: instance.name,
          value: instance.id,
        };
      });
    }
  }
}
