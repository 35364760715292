export namespace AppConstants {

  export enum AccountType {
    ODP = 'ODP',
    HUB = 'Hub'
  }

  export enum AppFeature {
    DESTINATION_APP = '1'
  }
}
