import { DataTablePagingProps } from '@zaiusinc/hera';
import { action, computed, observable, reaction } from 'mobx';
import { ZipApi } from '../lib/ZipApi';
import { DataSyncItem, SyncStrategy } from '../types/DataSyncItem';
import { directoryStore } from './DirectoryStore';
import { getValueByPath } from '../lib/utils';
import { DataSyncStrategyProvider } from '../pages/DataSync/DataSyncStrategyProvider';

export class DataSyncStore {
  @observable public searchFilter: string = '';
  @observable public filterByStatus: string = 'all';
  @observable public sortBy: string = 'lastExecution.created_at';
  @observable public sortDirection: number = 0;
  @observable public loading = false;

  @observable private _allDataSyncs: DataSyncItem[] = [];
  @observable private _paging: DataTablePagingProps = {
    rowStart: 1,
    rowCount: 100,
    total: 1000,
  };

  constructor() {
    reaction(
      () => directoryStore.trackerId,
      () => {
        if (directoryStore.trackerId) {
          this.fetch();
        }
      }
    );
  }

  public async fetch() {
    try {
      this.loading = true;
      this._allDataSyncs = [];
      const offset = Math.floor(this._paging.rowStart / this._paging.rowCount);
      const response = await ZipApi.listDataSyncs(
        offset,
        this._paging.rowCount,
        directoryStore.trackerId
      );
      this._allDataSyncs =
        response.dataSyncItems?.map((item) => new DataSyncItem(item)) || [];
    } finally {
      this.loading = false;
    }
  }

  @action
  public async toggleDataSync(dataSyncId: string) {
    const dataSync = this._allDataSyncs.find((item) => item.id === dataSyncId);
    if (dataSync) {
      const func = dataSync.enabled
        ? ZipApi.disableDataSync.bind(ZipApi)
        : ZipApi.enableDataSync.bind(ZipApi);
      if (await func(dataSyncId)) {
        dataSync.enabled = !dataSync.enabled;
      }
    }
  }

  @computed
  public get dataSyncs(): DataSyncItem[] {
    let filteredList = this._allDataSyncs;

    const searchTerm = this.searchFilter.trim().toLowerCase();
    if (searchTerm) {
      filteredList = filteredList.filter(
        ({ name, source, destination }: DataSyncItem) =>
          [name, source.object.object_name, destination.object.object_name].some((field) =>
            field.toLowerCase().includes(searchTerm)
          )
      );
    }

    if (this.filterByStatus !== 'all') {
      filteredList = filteredList.filter(
        (data: DataSyncItem) => {
          if (this.filterByStatus === 'active') {
            return data.enabled || DataSyncStrategyProvider.getStrategy(data) === SyncStrategy.WEBHOOK;
          } else {
            return DataSyncStrategyProvider.getStrategy(data) === SyncStrategy.SCHEDULED && !data.enabled;
          }
        }
      );
    }

    return filteredList.sort((a, b) => {
      return this.sortDirection
        ? (getValueByPath(a, this.sortBy) || '').localeCompare(
            getValueByPath(b, this.sortBy) || ''
          )
        : (getValueByPath(b, this.sortBy) || '').localeCompare(
            getValueByPath(a, this.sortBy) || ''
          );
    });
  }

  @action
  public async createDataSync(dataSync: DataSyncItem) {
    try {
      this.loading = true;
      const response = await ZipApi.createDataSync(dataSync);
      this._allDataSyncs.unshift(response.dataSync);
    } finally {
      this.loading = false;
    }
  }

  @action
  public async getDataSync(dataSyncId: string) {
    try {
      this.loading = true;
      const { dataSync } = await ZipApi.getDataSync(dataSyncId);
      return dataSync;
    } finally {
      this.loading = false;
    }
  }

  @action
  public async updateDataSync(dataSync: DataSyncItem) {
    try {
      this.loading = true;
      const response = await ZipApi.updateDataSync(dataSync);
      const index = this._allDataSyncs.findIndex(
        (item) => item.id === dataSync.id
      );
      if (index !== -1) {
        this._allDataSyncs[index] = response.dataSync;
      }
    } finally {
      this.loading = false;
    }
  }

  @action
  public async deleteDataSync(dataSyncId: string) {
    await ZipApi.deleteDataSync(dataSyncId);
    this._allDataSyncs = this._allDataSyncs.filter(
      (item) => item.id !== dataSyncId
    );
  }

  @computed
  public get paging() {
    return this._paging;
  }

  @action
  public setPaging(paging: DataTablePagingProps) {
    if (paging.rowStart <= 0) {
      paging.rowStart = 1;
    }
    this._paging = paging;
    this.fetch();
  }
}

export const dataSyncStore = new DataSyncStore();
