import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import styles from './FieldMappings.module.sass';
import { FieldMappings, ObjectFieldDefinition } from '../../../../types/DataSyncItem';
import MappingRow, { SrcDestPair } from './MappingRow';
import { Label, Link } from '@zaiusinc/hera';
import Notification from '../Notification';
import { ValidateHandle } from './ValidateHandle';

export interface FieldMappingsProps {
  disabled?: boolean;
  fieldMappings: FieldMappings;
  sourceFields: ObjectFieldDefinition[];
  destinationFields: ObjectFieldDefinition[];
  onChange: (fields: FieldMappings) => void;
}

/**
 * Parse the input field mappings into a list of source-destination pairs
 * @param fieldMappings
 */
const parseInput = (fieldMappings: FieldMappings): SrcDestPair[] => {
  if (!fieldMappings?.mappings) {
    return [];
  }
  return Object.entries(fieldMappings.mappings).map(([destination, {source_field}]) => ({
    source: source_field,
    destination,
  }));
};

/**
 * Create the output field mappings from a list of source-destination pairs
 * @param mappings
 */
const createOutput = (mappings: SrcDestPair[]): Record<string, { source_field: string }> => {
  const data: Record<string, { source_field: string }> = {};
  mappings.forEach((item) => {
    if (!item.source || !item.destination) {
      return;
    }
    data[item.destination] = {
      source_field: item.source,
    };
  });
  return data;
};

const FieldMappingsComponent = forwardRef<ValidateHandle, FieldMappingsProps>((props, ref) => {
  const {fieldMappings, sourceFields, destinationFields, onChange, disabled} = props;

  const [mapping, setMapping] = useState<SrcDestPair[]>(parseInput(fieldMappings));
  const [errors, setErrors] = React.useState<{ [key: string]: boolean }>({
    noneMapping: false,
    hasEmpty: false,
    incompatibleTypes: false,
    duplicateDestination: false
  });

  useImperativeHandle(ref, () => ({
    validate: () => {
      const hasEmpty = mapping.some((item) => !item.source || !item.destination);
      const noneMapping = mapping.length === 0;

      setErrors({
        ...errors,
        noneMapping,
        hasEmpty,
        duplicateDestination: errors.duplicateDestination
      });

      return !(noneMapping || hasEmpty || errors.duplicateDestination);
    },
  }));

  const isValidateFieldTypes = useCallback(() => {
    if (mapping.length === 0) {
      return true;
    }

    const typeMapping = {
      string: ['string', 'integer', 'float', 'boolean', 'timestamp'],
      integer: ['integer'],
      float: ['float'],
      boolean: ['boolean']
    };

    return mapping.every((item) => {
      const sourceField = sourceFields.find((field) => field.name === item.source);
      const destinationField = destinationFields.find((field) => field.name === item.destination);
      if (!sourceField || !destinationField) {
        return true;
      }

      const sourceType = sourceField.type.toLowerCase();
      const destinationType = destinationField.type.toLowerCase();

      return typeMapping[destinationType]?.includes(sourceType);
    });

  }, [mapping, sourceFields, destinationFields]);

  const handleAddItem = (item: SrcDestPair) => {
    const newMapping = [...mapping, item];
    updateMapping(newMapping);
  };

  const handleEditItem = (newValues: SrcDestPair, index: number) => {
    const newMapping = mapping.slice();
    newMapping[index] = {source: newValues.source, destination: newValues.destination};
    updateMapping(newMapping);
  };

  const handleDelete = (index: number) => {
    const newMapping = mapping.filter((_, i) => i !== index);
    updateMapping(newMapping);
  };

  const updateMapping = (newMapping: SrcDestPair[]) => {
    const newDestinations = new Set(newMapping.map((item) => item.destination));
    const duplicateDestination = newDestinations.size !== newMapping.length;

    setMapping(newMapping);
    onChange({mappings: createOutput(newMapping)});
    setErrors((prev) => ({...prev, duplicateDestination}));
  };

  const canAddNew = useCallback(() => {
    if (mapping.length === 0) {
      return true;
    }
    return mapping[mapping.length - 1].source !== null && mapping[mapping.length - 1].destination !== null;

  }, [mapping]);
  return (
    <div className={styles.container}>
      <Label
        disabled={disabled}
        label="Field Mapping"
        subLabel="Field mappings connect source fields with matching destination fields.">
        <></>
      </Label>

      {mapping.length > 0 && <div className={styles.labels}>
        <Label disabled={disabled} className={styles.sourceLabel} label="Source Field"><></>
        </Label>
        <Label disabled={disabled} className={styles.destinationLabel} label="Destination Field"><></>
        </Label>
      </div>}

      {mapping.map((field, index: number) => (
        <MappingRow
          key={index}
          disabled={!!disabled}
          initialValues={field}
          onChange={(newValues) => handleEditItem(newValues, index)}
          onDelete={() => handleDelete(index)}
          sourceFields={sourceFields}
          destinationFields={destinationFields}
        />
      ))}

      <Link disabled={disabled || !canAddNew()}
        onClick={() => {
          handleAddItem({source: null, destination: null});
          setErrors({...errors, noneMapping: false});
        }}>
        <div>+ Add Mapping</div>
      </Link>
      <Notification
        type="warning"
        show={!isValidateFieldTypes()}
        message="The source and destination types are incompatible. This could result in data loss during conversion"
      />
      <Notification
        type="error" show={errors.hasEmpty}
        message="Please make sure all source and destination fields are selected"
      />
      <Notification
        type="error" show={errors.noneMapping}
        message="Please configure at least one field mapping"
      />
      <Notification
        type="error"
        show={errors.duplicateDestination}
        message="A destination field can only be mapped once"
      />
    </div>
  );
});

export default FieldMappingsComponent;
