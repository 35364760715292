import {AnalyticsBrowser} from '@segment/analytics-next';
import {directoryStore} from '../stores/DirectoryStore';

export class SegmentAnalytics {

  public static async identify() {
    const traits = (await this.analytics.user()).traits();
    if (traits?.productName === 'ODP') {
      await this.analytics.reset();
    }

    if (directoryStore.prismContext) {
      const context = directoryStore.prismContext;
      await this.analytics.identify(context.originalUser.email, {
        groupId: context.organization.masterCustomerId,
        groupName: context.organization.name,
        instanceId: context.account.instanceId,
        instanceName: context.account.name,
        isOptiUser: context.originalUser.isInternalUser,
        name: context.originalUser.name,
        odpAccountId: context.account.id,
        organizationId: context.organization.turnstileOrganizationId
      });

      await this.analytics.group(context.organization.masterCustomerId, {
        hasTurnstile: !!context.organization.turnstileOrganizationId,
        name: context.organization.name,
        odpAccountId: context.account.id,
        organizationId: context.organization.turnstileOrganizationId,
      });
    }
  }

  public static async page(page: string, properties?: Record<string, any>) {
    if (directoryStore.prismContext) {
      const context = directoryStore.prismContext;
      await this.analytics.page(page, {
        groupId: context.organization.masterCustomerId,
        userId: context.originalUser.email,
        productName: context.productName,
        subProductName: context.subProductName,
        ...properties,
      });
    }
  }

  public static async track(message: string, properties?: Record<string, any>) {
    if (directoryStore.prismContext) {
      const context = directoryStore.prismContext;
      await this.analytics.track(message, {
        groupId: context.organization.masterCustomerId,
        instanceId: context.account.instanceId,
        instanceName: context.account.name,
        isOptiUser: context.originalUser.isInternalUser,
        name: context.originalUser.name,
        odpAccountId: context.account.id,
        organizationId: context.organization.turnstileOrganizationId,
        userId: context.originalUser.email,
        productName: context.productName,
        subProductName: context.subProductName,
        ...properties,
      });
    }
  }

  private static analytics: AnalyticsBrowser =
    AnalyticsBrowser.load({writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY!});
}
