import * as yaml from 'js-yaml';

import {CanUninstallResponse, SubmitFormDataResponse, ZipApi} from '../lib/ZipApi';
import {computed, action as mobxAction, observable, reaction} from 'mobx';
import {showToasts, toaster} from '../lib/toaster';

import {AppLogEntry} from '../types/AppLogEntry';
import {AppVersion} from '../types/AppVersion';
import {AppVersionMetadata} from '../types/AppVersionMetadata';
import {HostPageMessenger} from '../lib/HostPageMessenger';
import {Intent} from '@blueprintjs/core';
import {Schema} from '@zaiusinc/app-forms-schema';
import {directoryStore} from './DirectoryStore';
import {subHours} from 'date-fns';

const ZAIUS_AUTH_URI: string = process.env.REACT_APP_ZAIUS_AUTH_URI!;

const IFRAME_PARENT_ORIGIN: string = process.env.REACT_APP_IFRAME_PARENT_ORIGIN || (() => {
  const url = new URL(window.location.href);
  const stem = url.host.split('.').slice(1);
  if (stem[stem.length - 2] === 'zaius') {
    url.host = 'app.zaius.com';
    if (stem[0] === 'staging') {
      url.host = 'staging.zaius.com';
    }
    return url.origin;
  }
  stem[1] = 'odp';
  url.host = ['app'].concat(stem).join('.');
  return url.origin;
})();

export interface HandleActionResponse {
  success: boolean;
  redirectMode?: 'url' | 'settings';
  redirectTarget?: string;
}

const LOG_PAGE_SIZE = 200;
export interface AppLogFilter {
  search?: string;
  level?: string;
  startDate?: Date;
  endDate?: Date;
}

export class AppDetailStore {
  @observable public loading = false;
  @observable public status?: number;
  @observable public version?: string;
  @observable public detail?: AppVersionMetadata;
  @observable public installId?: number;
  @observable public installedVersion?: string;
  @observable public settingsFormSchema?: Schema.Form;
  @observable public settingsFormData?: Schema.FormData;
  @observable public settingsFormErrors = {sections: observable.map<string, string[]>({})};
  @observable public installing = false;
  @observable public uninstalling = false;
  @observable public moreLogLoaded = false;
  @observable public logLoaded = false;
  @observable public allAppLogs: AppLogEntry[] = [];
  @observable public logFilter: AppLogFilter = {startDate: subHours(new Date(), 1), endDate: new Date()};
  @observable public cursor_token?: string;
  @observable public hasMoreLogs = true;

  constructor(public appId: string, public requestedVersion?: string) {
    this.version = requestedVersion;
    reaction(() => directoryStore.trackerId, () => this.fetch(), {fireImmediately: true, delay: 100});
  }

  public get installed() {
    return !!this.installId;
  }

  public get sameVersionInstalled() {
    return !!this.installId && (this.installedVersion === this.version);
  }

  public get hasTroubleshooting() {
    const vendor = this.detail?.vendor.toLocaleLowerCase();
    return this.sameVersionInstalled && vendor !== 'zaius' && vendor !== 'optimizely';
  }

  public async install(): Promise<boolean> {
    if (this.installed || !directoryStore.trackerId) {
      return false;
    }
    this.installing = true;
    try {
      await ZipApi.install(directoryStore.trackerId, this.appId, this.requestedVersion);
      await this.fetch();
      this.sendJumbeEvent('install_success');
      return true;
    } catch (e) {
      console.error(e);
      this.installing = false;
      this.sendJumbeEvent('install_failure');
      return false;
    }
  }

  public async canUninstall(): Promise<CanUninstallResponse> {
    if (this.installId) {
      return await ZipApi.canUninstall(this.installId);
    } else {
      throw new Error(`Install id is not set`);
    }
  }

  public async uninstall(): Promise<boolean> {
    if (!this.installed || !directoryStore.trackerId) {
      return false;
    }
    this.uninstalling = true;
    try {
      await ZipApi.uninstall(directoryStore.trackerId, this.appId);
      await this.fetch();
      this.uninstalling = false;
      this.sendJumbeEvent('uninstall_success');
      return true;
    } catch (e: any) {
      console.error(e);
      this.uninstalling = false;
      let message = 'Unable to perform uninstall at this time.  Please try again.';
      if (e.response && e.response.status && e.response.status === 400) {
        try {
          message = (await e.response.json()).message;
        } catch (e) {
          // eating this just in case there was a problem parsing the body as json
        }

      } else {
        this.sendJumbeEvent('uninstall_failure');
      }

      toaster.show({message, intent: Intent.DANGER});
      return false;
    }
  }

  public async handleAction(
    section: string, action: string, data: Schema.FormSectionData): Promise<HandleActionResponse> {
    return action === 'oauth'
      ? await this.authorizationRequest(section, data)
      : await this.submitFormSection(section, action, data);
  }

  public processErrors(errors: SubmitFormDataResponse['response_json']['errors']) {
    const errorsBySection: Schema.FormErrors['sections'] = {};
    for (const key of Object.keys(errors)) {
      const [sectionKey, field] = key.split('.');
      if (!errorsBySection[sectionKey]) {
        errorsBySection[sectionKey] = {};
      }
      errorsBySection[sectionKey][field] = errors[key];
    }
    this.settingsFormErrors.sections.replace(errorsBySection);
  }

  public sendJumbeEvent(action: string, data: any = {}) {
    HostPageMessenger.send('event', {
      type: 'app',
      action,
      zip_app_id: this.appId,
      zip_app_version: this.version,
      ...data
    });
  }

  public async fetchLogs() {
    this.logLoaded = false;
    this.moreLogLoaded = true;
    const criteria = this.buildCreteria();
    const payload = {
      criteria,
    };
    const logEntries = await ZipApi.listAppLogEntries(JSON.stringify(payload));
    this.allAppLogs = logEntries.log_entry || [];
    this.cursor_token = logEntries.cursor_token;
    this.hasMoreLogs = this.allAppLogs.length === LOG_PAGE_SIZE;
    this.logLoaded = true;
  }

  public async fetchMore() {
    if (this.version) {
      this.moreLogLoaded = false;
      this.logLoaded = true;
      const criteria = this.buildCreteria();
      const payload = {
        criteria,
        cursor_token: this.cursor_token
      };
      const logEntries = (await ZipApi.listAppLogEntries(JSON.stringify(payload)));
      this.allAppLogs = this.allAppLogs.concat(logEntries.log_entry || []);
      this.cursor_token = logEntries.cursor_token;
      this.hasMoreLogs = logEntries.hasOwnProperty('log_entry') &&  logEntries.log_entry.length === LOG_PAGE_SIZE;
      this.moreLogLoaded = true;
    }
  }

  @computed
  public get appLogs(): AppLogEntry[] {
    return this.allAppLogs.filter((logEntry) => {
      let keep = true;
      if (this.logFilter.search) {
        const text = logEntry.message;
        keep = keep && this.logFilter.search.toLocaleLowerCase().split(/\W/g).every(
          (term) => text.toLowerCase().includes(term)
        );
      }
      if (this.logFilter.level && this.logFilter.level.toLocaleLowerCase() !== 'all') {
        keep = keep && logEntry.level.toLowerCase() === this.logFilter.level.toLowerCase();
      }
      return keep;
    });
  }

  @computed
  public get appLogsContent(): string {
    return this.appLogs.map((entry) => {
      return this.formatAppLogLine(entry);
    }).join('\n');
  }

  public setLogFilter(filter: AppLogFilter) {
    this.logFilter = filter;
    this.fetchLogs();
  }

  private buildCreteria() {
    const term = this.logFilter.search;
    return {
      context: {
        app_id: this.appId,
        version: this.version,
        trackerId: directoryStore.trackerId!
      },
      search_strings: term == null ? [] : [term],
      level: this.logFilter.level?.toUpperCase(),
      time_frame: {
        start: this.logFilter.startDate?.toISOString(),
        end: this.logFilter.endDate?.toISOString()
      },
      page_size: LOG_PAGE_SIZE
    };
  }

  private formatAppLogLine(appLogEntry: AppLogEntry) {
    return `${appLogEntry.time} ${appLogEntry.level} ${appLogEntry.message}`;
  }

  private async authorizationRequest(section: string, data: Schema.FormSectionData): Promise<HandleActionResponse> {
    const payload = {
      form_page: section,
      form_data_json: JSON.stringify(data),
      target_url: IFRAME_PARENT_ORIGIN
    };
    const result = await ZipApi.authorizationRequest(this.installId!, JSON.stringify(payload));
    const response: HandleActionResponse = {success: true};
    if (result.response_json) {
      const {errors, toasts, redirectMode} = result.response_json;
      if (toasts.length !== 0) {
        showToasts(toasts);
      } else if (Object.keys(errors).length) {
        this.processErrors(errors);
      } else if (redirectMode === 'url') {
        const params = [
          `tracker=${directoryStore.trackerId!}`,
          `request_id=${result.auth_request_id}`,
        ];
        const targetUrl = `${ZAIUS_AUTH_URI}/${this.appId}?${params.join('&')}`;
        response.redirectMode = 'url';
        response.redirectTarget = targetUrl;
      }
    } else {
      this.settingsFormErrors.sections.clear();
    }
    return response;
  }

  private async submitFormSection(
    section: string, action: string, data: Schema.FormSectionData): Promise<HandleActionResponse> {
    const payload = {
      form_page: section,
      action,
      form_data_json: JSON.stringify(data)
    };
    const result = await ZipApi.submitFormData(this.installId!, JSON.stringify(payload));
    const response: HandleActionResponse = {success: true};

    if (result.response_json) {
      const {errors, toasts} = result.response_json;
      if (toasts.length === 0) {
        toasts.push({intent: 'success', message: 'Updated Settings'});
      }
      showToasts(toasts);
      this.processErrors(errors);
    } else {
      this.settingsFormErrors.sections.clear();
    }
    if (result.form_data_json) {
      this.settingsFormData = result.form_data_json;
    }
    const {redirect, redirectMode} = result.response_json;

    if (redirect) {
      response.redirectTarget = redirect;
      response.redirectMode = redirectMode;
    }
    return response;
  }

  @mobxAction('AppDetailStore<Fetch>')
  private async fetch() {
    this.loading = true;
    this.detail = undefined;
    this.installId = undefined;
    if (directoryStore.trackerId) {
      await this.fetchInstalledVersion();
    }
    await this.fetchAppDetail();
    this.loading = false;
    this.installing = false;
    if (this.detail) {
      this.getSettingsFormSchema();
      this.getSettingsFormData();
    }
  }

  private async fetchInstalledVersion() {
    try {
      const result = await ZipApi.listAppInstallations(directoryStore.trackerId!);
      const install = result.installations.find((appInstall) => appInstall.app_id === this.appId);
      this.installId = install ? install.id : undefined;
      this.installedVersion = install ? install.version : undefined;
      if (!this.requestedVersion) {
        this.version = this.installedVersion;
      }
    } catch (error) {
      console.error(error);
      // TODO: Toast and return to previous page
    }
  }

  private async fetchAppDetail() {
    try {
      let app: AppVersion;
      if (this.version) {
        app = (await ZipApi.fetchAppVersion(this.appId, this.version)).app_version;
      } else {
        app = (await ZipApi.fetchApp(this.appId)).app;
      }
      this.detail = app.metadata;
      this.version = app.id.version;
      this.status = 200;
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.status) {
        this.status = error.response.status;
      }
      if (this.status !== 404) {
        toaster.show({message: 'There was a problem loading the requested page', intent: Intent.DANGER});
      }
    }
  }

  private async getSettingsFormSchema() {
    const response = await fetch(this.detail!.settings_url);
    this.settingsFormSchema = yaml.safeLoad(await response.text()) as Schema.Form;
  }

  private async getSettingsFormData() {
    if (!this.installed) {
      return {sections: []};
    }
    const result = await ZipApi.fetchFormData(this.installId!);
    this.settingsFormData = result.form_data_json;
  }
}
