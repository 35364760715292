import {Schema} from '@zaiusinc/app-forms-schema';
import {isEqual} from 'lodash';
import {action, observable, ObservableMap, toJS} from 'mobx';
import {FormOptions} from '../AppForm';

export type FormChangeHandler = (section: string, field: string, value: Schema.FormValue) => void;
export type FeatureFlagChecker = (flag: string) => boolean;
const DEFAULT_OPTIONS: FormOptions = Object.freeze({
  controlled: false,
  allowButtons: true,
  minimalAccordions: true,
  showErrorBadge: true,
  allowLoadingState: true
});

export class DataStore {
  @observable public loading = false;
  @observable public form: Schema.Form;
  @observable public options: FormOptions = DEFAULT_OPTIONS;
  @observable private sourceData: Schema.FormData;
  @observable private updates = observable.map<string, ObservableMap<Schema.FormValue>>();

  constructor(
    sourceData: Schema.FormData,
    form: Schema.Form,
    public hasFeatureFlag: FeatureFlagChecker,
    private changeHandler?: FormChangeHandler,
    options?: Partial<FormOptions>
  ) {
    this.form = form;
    this.sourceData = sourceData;
    this.setOptions(options);
  }

  public destroy() {
    this.sourceData = {};
    this.updates.clear();
  }

  @action
  public updateSchema(form: Schema.Form) {
    this.form = form;
  }

  @action
  public setSourceData(data: Schema.FormData) {
    if (!isEqual(this.sourceData, data)) {
      this.clearUpdates();
      this.sourceData = data;
    }
  }

  @action
  public setOptions(options?: Partial<FormOptions>) {
    this.options = {...DEFAULT_OPTIONS, ...options};
  }

  @action
  public update(section: string, field: string, value: Schema.FormValue) {
    if (!this.updates.has(section)) {
      this.updates.set(section, observable.map());
    }
    this.updates.get(section)!.set(field, value);
    if (this.changeHandler) {
      this.changeHandler(section, field, value);
    }
  }

  public clearUpdates() {
    this.updates.clear();
  }

  public get assetsBaseUrl(): string {
    return this.options.assetsBaseUrl || '';
  }

  public get(section: string, field: string): Schema.FormValue {
    if (this.updates.has(section) && this.updates.get(section)!.has(field)) {
      return toJS(this.updates.get(section)!.get(field)) ?? null;
    } else if (this.sourceData[section] && Object.keys(this.sourceData[section]).includes(field)) {
      return toJS(this.sourceData[section][field]);
    } else {
      const element = this.form.sections.find((s) => s.key === section)?.elements?.find((e) => e.key === field);
      if (element) {
        return toJS((element as any).defaultValue) ?? null;
      }
    }
    return null;
  }

  public getSection(section: Schema.Section | Schema.ZedSection) {
    return Object.assign(
      this.defaultValuesForSection(section),
      toJS(this.sourceData[section.key]),
      toJS(this.updates.get(section.key) ?? {})
    );
  }

  public getAllData(): Schema.FormData {
    const data: Schema.FormData = {};
    for (const section of this.form.sections) {
      data[section.key] = this.getSection(section);
    }
    return data;
  }

  private defaultValuesForSection(section: Schema.Section | Schema.ZedSection) {
    const values: Schema.FormSectionData = {};
    section.elements?.forEach((element) => {
      if (element.key && 'defaultValue' in element && element.defaultValue !== undefined) {
        values[element.key] = element.defaultValue;
      }
    });
    return values;
  }
}
