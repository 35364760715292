import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { DataSyncItem, OptimizelyProduct } from '../../../types/DataSyncItem';
import React from 'react';
import styles from './DataSyncIcons.module.sass';

interface DataSyncIconsProps {
  dataSync?: DataSyncItem;
}

const DataSyncIcons: React.FC<DataSyncIconsProps> = ({dataSync}) => {
  const altText = dataSync?.source.object.object_display_name || dataSync?.source.object.object_name || '';
  const sourceIcon = dataSync?.source.object.object_icon_url ?
    <img width={30} src={dataSync?.source.object.object_icon_url} alt={altText} /> :
      (dataSync?.source.product === OptimizelyProduct.CMP ? <img width={30} src={'/assets/cmp.svg'}
        alt={altText} /> : <img width={30} src={'/assets/data_platform.svg'} alt={altText} />);

  const destinationIcon = dataSync?.destination.product === OptimizelyProduct.CGR ?
    <img width={30} src="/assets/content_graph.svg" alt="Content Graph"/> :
    <img width={30} src="/assets/data_platform.svg" alt="Optimizely"/>;

  return (
    <div className={styles.container}>
      <div className={styles.iconWrapper}>{sourceIcon}</div>
      <Icon icon={IconNames.CARET_RIGHT}/>
      <div className={styles.iconWrapper}>{destinationIcon} </div>
    </div>
  );
};

export default DataSyncIcons;
