import equal from 'fast-deep-equal';
import {LocationDescriptorObject} from 'history';
import * as queryString from 'querystring';
import React from 'react';
import {Route, RouteComponentProps, Switch, useParams, withRouter, useLocation} from 'react-router-dom';
import {HostPageMessenger} from './lib/HostPageMessenger';
import {showToasts} from './lib/toaster';
import {AppDetailPage} from './pages/AppDetailPage';
import {AppsListPage} from './pages/AppsListPage';
import {SegmentAnalytics} from './lib/SegmentAnalytics';
import {directoryStore} from './stores/DirectoryStore';
import {reaction} from 'mobx';
import { DataSyncListPage } from './pages/DataSync/DataSyncListPage';
import CreateDataSyncPage from './pages/DataSync/CreateDataSyncPage';
import EditDataSyncPage from './pages/DataSync/EditDataSyncPage';
import { DataSyncExecutionsPage } from './pages/DataSync/DataSyncExecutionsPage';

class Directory extends React.Component<RouteComponentProps, {}> {
  private disposer: any;

  public render() {
    return (
      <Switch>
        <Route path="/app/:appId/:version">
          <AppDetailPageRenderer />
        </Route>
        <Route path="/app/:appId">
          <AppDetailPageRenderer />
        </Route>
        <Route exact path="/">
          <AppsListPage />
        </Route>
        <Route exact path="/data_syncs">
          <DataSyncListPage />
        </Route>
        <Route exact path="/data_syncs/new">
          <CreateDataSyncPage />
        </Route>
        <Route exact path="/data_syncs/:dataSyncId">
          <EditDataSyncPage />
        </Route>
        <Route exact path="/data_syncs/:dataSyncId/executions">
          <DataSyncExecutionsPage />
        </Route>
        <Route path="/">
          <div>404</div>
        </Route>
      </Switch>
    );
  }

  public async componentDidUpdate(prevProps: Readonly<RouteComponentProps>, prevState: Readonly<{}>, snapshot?: any) {
    const {pathname, search, hash} = this.props.location;
    if (prevProps && prevProps.location && !equal(this.props.location, prevProps.location)) {
      HostPageMessenger.send('navigate', {pathname, search, hash});
    }
    const params = queryString.parse(search.startsWith('?') ? search.substring(1) : search);
    if (params.toasts) {
      showToasts(JSON.parse(atob(params.toasts as string)));
      delete params.toasts;
      this.props.history.replace({...this.props.location, search: queryString.encode(params)});
    }

    if (directoryStore.trackerId) {
      await this.trackAnalytics(params, pathname + search);
    }
  }

  public async componentDidMount() {
    HostPageMessenger.on('navigate', (location: LocationDescriptorObject) => {
      this.props.history.replace(location);
    });
    HostPageMessenger.send('readyToNavigate');

    if (directoryStore.prismContext && directoryStore.trackerId) {
      await SegmentAnalytics.identify();
    } else {
      this.disposer = reaction(() => [directoryStore.prismContext, directoryStore.trackerId], () => {
        if (directoryStore.prismContext && directoryStore.trackerId) {
          SegmentAnalytics.identify();
        }
      });
    }
  }

  public componentWillUnmount() {
    // Cleanup the reaction to prevent memory leaks
    if (this.disposer) {
      this.disposer();
    }
  }

  private async trackAnalytics(params: queryString.ParsedUrlQuery, path: string) {
    if (params?.section && params?.tab) {
      await SegmentAnalytics.track('App Section', {page: params.tab, section: params.section, path});
    } else if (params?.tab) {
      await SegmentAnalytics.track('App', {page: params.tab, path});
    } else {
      let page: string;

      if (path === '/') {
        page = 'All Apps';
      } else if (path.startsWith('/app/')) {
        page = 'App Detail';
      } else if (path.startsWith('/data_syncs')) {
        page = 'Data Syncs';
      } else {
        page = path;
      }
      await SegmentAnalytics.page(page, {path});
    }
  }
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function AppDetailPageRenderer() {
  const {appId, version} = useParams<{ appId: string, version: string }>();
  const standalone = useQuery().has('standalone');
  return <AppDetailPage appId={appId!} version={version} hideBreadcrumbs={standalone} />;
}

export const DirectoryWithRouter = withRouter(Directory);
