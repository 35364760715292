import { action, observable, reaction } from 'mobx';
import { ZipApi } from '../lib/ZipApi';
import { directoryStore } from './DirectoryStore';
import { ObjectDefinition, ObjectFieldDefinition } from '../types/DataSyncItem';
import { DataSyncObjectsStore } from './DataSyncObjectsStore';
import { showToasts } from '../lib/toaster';

export default class DataSyncCgObjectsStore extends DataSyncObjectsStore {
  @observable public loading = false;
  @observable public instanceId: string | null = null;

  @observable private _objectDefinitions: ObjectDefinition[] = [];

  @action
  public async fetch() {
    try {
      this.loading = true;
      this._objectDefinitions = [];
      if (directoryStore.trackerId && this.instanceId) {
        this._objectDefinitions = await ZipApi.listCGSchemas(directoryStore.trackerId, this.instanceId);
        this._objectDefinitions.forEach((object) => {
          if (object.fields) {
            object.fields = object.fields.sort((a, b) => a.name.localeCompare(b.name));
          }
        });
      }
    } catch (e) {
      showToasts([{intent: 'danger', message: 'Unable to fetch content graph schema, please try again later.'}]);
    } finally {
      this.loading = false;
    }
  }

  @action
  public setInstanceId(instanceId: string) {
    this.instanceId = instanceId;
    this.fetch();
  }

  public getObjectList(): ObjectDefinition[] {
    return this._objectDefinitions;
  }

  public getObjectFields(objectName: string): ObjectFieldDefinition[] {
    const object = this._objectDefinitions.find((obj) => obj.object_name === objectName);
    return object?.fields || [];
  }
}
