import { DataSyncItem, OptimizelyProduct } from '../../types/DataSyncItem';
import { SyncStrategy } from '../../types/DataSyncItem';

export class DataSyncStrategyProvider {
  public static getStrategy(dataSync: DataSyncItem) {
    if (dataSync.source.product === OptimizelyProduct.CMP) {
      return SyncStrategy.WEBHOOK;
    }
    return SyncStrategy.SCHEDULED;
  }
}
