import { observable, reaction, action } from 'mobx';
import { ZipApi } from '../lib/ZipApi';
import { directoryStore } from './DirectoryStore';
import { ObjectDefinition, ObjectFieldDefinition } from '../types/DataSyncItem';
import { DataSyncObjectsStore } from './DataSyncObjectsStore';

export default class DataSyncOdpObjectsStore extends DataSyncObjectsStore {

  @observable private _objectDefinitions: ObjectDefinition[] = [];

  public constructor() {
    super();
    reaction(() => directoryStore.trackerId, () => {
      if (directoryStore.trackerId) {
        this.fetch();
      }
    }, {fireImmediately: true});
  }

  @action
  public async fetch() {
    try {
      this.loading = true;
      this._objectDefinitions = [];

      if (directoryStore.trackerId) {
        this._objectDefinitions = await ZipApi.listODPSchemas(directoryStore.trackerId);
        this._objectDefinitions = this._objectDefinitions.sort((a, b) =>
          a.object_display_name.localeCompare(b.object_display_name));
        this._objectDefinitions.forEach((object) => {
          if (object.fields) {
            object.fields = object.fields.sort((a, b) => a.name.localeCompare(b.name));
          }
        });
      }
    } finally {
      this.loading = false;
    }
  }

  public getObjectList(): ObjectDefinition[] {
    return this._objectDefinitions;
  }

  public getObjectFields(objectName: string): ObjectFieldDefinition[] {
    const object = this._objectDefinitions.find((obj) => obj.object_name === objectName);
    if (!object) {
      return [];
    }
    return object.fields;
  }

  public setInstanceId(): void {
    throw new Error('Method not implemented.');
  }
}
