import {
  Comparator,
  EmptyComparator,
  EqualsComparator,
  Evaluation,
  FeatureFlagComparator,
  ListComparator,
  Predicate,
  RegexComparator
} from '@zaiusinc/app-forms-schema';
import {isObservableArray} from 'mobx';
import {DataStore} from '../stores/DataStore';
import {primitiveValueOf} from './primitiveValueOf';

export class PredicateEvaluator {
  constructor(private section: string, private data: DataStore) {}

  public evaluate = (evaluation?: Evaluation, fallback = false): boolean => {
    if (evaluation == null) {
      return fallback;
    } else if (typeof evaluation === 'boolean') {
      return evaluation;
    } else if (this.isPredicate(evaluation)) {
      return this.evaluateList(evaluation.operation, evaluation.comparators, this.evaluate);
    } else if (this.isFeatureFlagComparator(evaluation)) {
      return this.data.hasFeatureFlag(evaluation.hasFeatureFlag);
    }

    // at this point it must be a Comparator or ListComparator
    const operation = (evaluation as ListComparator).operation || 'any';
    const [section, field] = this.qualify(evaluation.key);
    const value = primitiveValueOf(this.data.get(section, field));

    if (this.isEqualsComparator(evaluation)) {
      return this.evaluateList(operation, value, (v) => this.isEqual(v, evaluation.equals));
    } else if (this.isEmptyComparator(evaluation)) {
      if (this.isArray(value) && value.length === 0) {
        return operation !== 'none';
      }
      const result = this.evaluateList(operation, value, this.isEmpty);
      return evaluation.empty ? result : !result;
    } else if (this.isRegexComparator(evaluation)) {
      const regex = new RegExp(evaluation.regex, evaluation.flags);
      return this.evaluateList(operation, value, (v) => (v == null ? false : regex.test(v.toString())));
    }
    return false;
  };

  private evaluateList<T>(operation: string, value: T | T[], evaluator: (v: T) => boolean) {
    const values: T[] = this.isArray(value) ? value : [value];
    switch (operation) {
      case 'all':
        return values.every(evaluator);
      case 'any':
        return values.findIndex(evaluator) !== -1;
      case 'none':
        return values.every((v: T) => !evaluator(v));
      default:
        return false;
    }
  }

  private isArray<T>(value: any): value is T[] {
    return Array.isArray(value) || isObservableArray(value);
  }

  private isEmpty = (value: any): boolean => {
    return value == null || value === '' || (this.isArray(value) && value.length === 0);
  };

  private isEqual(a: any, b: any) {
    // special case: null/undefined should be considered falsy
    if (a === b || (a == null && b === false) || (a === false && b == null)) {
      return true;
    }
    return false;
  }

  private qualify(field: string) {
    return field && field.includes('.') ? field.split('.') : [this.section, field];
  }

  private isPredicate(evaluation: Evaluation): evaluation is Predicate {
    const predicate = evaluation as Predicate;
    return typeof predicate.operation === 'string' && this.isArray(predicate.comparators);
  }

  private isFeatureFlagComparator(evaluation: Evaluation): evaluation is FeatureFlagComparator {
    const predicate = evaluation as FeatureFlagComparator;
    return typeof predicate.hasFeatureFlag === 'string';
  }

  private isEqualsComparator(evaluation: Evaluation): evaluation is EqualsComparator {
    const comparator = evaluation as Comparator;
    return !!comparator.key && comparator.equals !== undefined;
  }

  private isEmptyComparator(evaluation: Evaluation): evaluation is EmptyComparator {
    const comparator = evaluation as Comparator;
    return !!comparator.key && typeof comparator.empty === 'boolean';
  }

  private isRegexComparator(evaluation: Evaluation): evaluation is RegexComparator {
    const comparator = evaluation as Comparator;
    return !!comparator.key && typeof comparator.regex === 'string';
  }
}
