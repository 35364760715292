import * as React from 'react';
import * as d3 from 'd3';
import throttle from 'lodash/throttle';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import uniq from 'lodash/uniq';
import {DataSeriesBuilder, ChartComponentBuilder} from './types/DataSeriesBuilder';
import {Provider} from 'mobx-react';
import { ChartArea } from './types';
import { createChartState, ChartState } from './ChartState';
import {ResizeSensor} from '@blueprintjs/core';

import './styles/Chart.sass';

export enum ChartThemes {
  DEFAULT,
  RED,
  BLUE,
  GREEN,
  RAINBOW,
  PASTEL
}

interface ChartMargin {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

interface ChartProps<T extends object> {
  children: Array<React.ReactElement<DataSeriesBuilder<any, any>>> | React.ReactElement<DataSeriesBuilder<any, any>>;
  margins?: Partial<ChartMargin>;
  height?: string;
  width?: string;
  theme?: string;
  data?: T[];
  state?: ChartState;
  /**
   * Ratio-width for whitespace between bars relative to bar size, 0 = no gaps, 1 = no bars (defaults to 0.5)
   */
  gapWidth?: number;
  numberOfTicks?: number;
}

export class Chart<T extends object> extends React.Component<ChartProps<T>> {
  private node: SVGSVGElement | null = null;
  private builders: Array<ChartComponentBuilder<any>> = [];
  private throttledRender = throttle(this.renderChart.bind(this), 500);
  // The minimum bar width, unless there isn't enough space for this width
  private static BAR_MIN_WIDTH = 25;
  // The maximum bar width
  private static BAR_MAX_WIDTH = 100;

  public static numberOfTicks(height: number): number {
    return Math.floor(height / 30);
  }

  public static defaultProps = {
    margins: {},
    height: '400px',
    width: '100%',
    theme: 'chart-theme-blue',
    state: createChartState()
  };

  public getScalePadding(chartArea: ChartArea, gapWidth?: number) {
    const numItems = Math.max(...this.seriesBuilders.map((item) => item.getData().length));
    const chartWidth = chartArea.right - chartArea.left;
    const spacePerBar = chartWidth / numItems;
    // The ideal bar width is 50% of the available width per bar, up to 100px
    const idealBarWidth = gapWidth !== undefined ? spacePerBar * (1 - gapWidth) : Math.min(Chart.BAR_MAX_WIDTH, spacePerBar * 0.5);
    // The minimum bar width is either the specified minimum width, or if the minimum width would be too
    // large to fit all the bars without overlap, it's the available space per bar -2px (to make sure there is a gap between bars)
    const minBarWidth = Math.min(Chart.BAR_MIN_WIDTH, spacePerBar - 2);
    // This gives us either the idealBarWidth, or if it would be less than the minimum bar width, gives us that instead.
    const barWidth = Math.max(idealBarWidth, minBarWidth);
    return (chartWidth - barWidth * numItems) / (barWidth + chartWidth);
  }

  public componentDidMount() {
    this.renderChart();
  }

  public componentDidUpdate() {
    this.renderChart();
  }

  get seriesBuilders() {
    return this.builders.filter((item) => item instanceof DataSeriesBuilder) as Array<DataSeriesBuilder<any, any>>;
  }

  get seriesMarginsRequirements(): ChartMargin {
    const initialMargins: ChartMargin = { left: 0, top: 0, right: 0, bottom: 0 };
    if (!this.builders.length) { return initialMargins; }
    const required = this.builders.reduce((acc, curr) => {
      const m = curr.getRequiredMargin();
      return {
        top: Math.max(acc.top, m.top),
        bottom: Math.max(acc.bottom, m.bottom),
        left: Math.max(acc.left, m.left),
        right: Math.max(acc.right, m.right),
      };
    }, initialMargins);
    // Override Margins
    return Object.assign(required, this.props.margins!);
  }

  get seriesDataKeys(): string[] {
    if (!this.builders.length) { return []; }
    const initialKeys: string[] = [];
    const keys: string[] = this.seriesBuilders.reduce((p, c) => {
      if (c.props.dataKey) { p.push(c.props.dataKey); }
      if (c.props.dataKeys) { p.push(...c.props.dataKeys); }
      return p;
    }, initialKeys);
    return uniq(keys);
  }

  public renderChart() {
    const {children} = this.props;
    if (!this.node || !children) { return; }

    const x: d3.scale.Ordinal<string, number> = d3.scale.ordinal();
    const margins = this.seriesMarginsRequirements;
    const { height, width } = this.node.getBoundingClientRect();
    const xDomain = Math.max(...this.seriesBuilders.map((item) => item.getData().length));
    const chartArea: ChartArea = {
      left: margins.left,
      top: margins.top,
      bottom: height - margins.bottom,
      right: width - margins.right,
      margins,
      height,
      width,
      chartElement: () => this.node
    };

    x.domain([...Array(xDomain).keys()].map((_item, index) => `${index}`));
    const gapWidthAbsolute = this.getScalePadding(chartArea, this.props.gapWidth);
    x.rangeBands([chartArea.left, chartArea.right], gapWidthAbsolute);

    const numberOfTicks = this.props.numberOfTicks || Chart.numberOfTicks(chartArea.bottom - chartArea.top);
    const buildersByAxis = groupBy(this.seriesBuilders, (b) => b.getYAxisName());
    const yScales = mapValues(buildersByAxis, (builders) => {
      const max = Math.max(...builders.map((item) => item.getRangeMax()));
      const scale = d3.scale.linear();
      scale.domain([0, max]);
      scale.range([chartArea.bottom, chartArea.top]).nice(numberOfTicks);
      return scale;
    });

    const dataKeys = this.seriesDataKeys;
    const selection = d3.select(this.node);
    if (height > 0 && width > 0) {
      for (const builder of this.builders) {
        const y = yScales[builder.getYAxisName()];
        const renderer = builder.build({chartArea, x, y, dataKeys, yScales, numberOfTicks});
        if (renderer) {
          selection.call(renderer);
        }
      }
    }
  }

  public render() {
    const { children, theme, state } = this.props;
    this.builders.splice(0, this.builders.length);
    return (
      <Provider builders={this.builders} chartState={state}>
        {/* @ts-ignore: ResizeSensor not JSX component */}
        <ResizeSensor onResize={this.throttledRender}>
          <div
            className={`chart ${theme}`}
            style={{
              height: this.props.height,
              width: this.props.width
            }}
          >
            <svg
              ref={(el) => this.node = el}
              style={{
                height: this.props.height,
                width: this.props.width
              }}
            >
              {children}
            </svg>
          </div>
        </ResizeSensor>
      </Provider>
    );
  }
}
