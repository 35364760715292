import {observable} from 'mobx';
import {HostPageMessenger} from '../lib/HostPageMessenger';
import {UserFeatures} from '../types/UserFeatures';
import {AppConstants} from '../types/AppConstants';
import AccountType = AppConstants.AccountType;
import {AccountDetails} from '../types/AccountDetails';
import {PrismContext} from '../types/PrismContext';

export class DirectoryStore {
  @observable public trackerId?: string;
  @observable public userFeatures?: UserFeatures;
  @observable public accountDetails?: AccountDetails;
  @observable public prismContext?: PrismContext;
  @observable public permittedApps?: string[];

  constructor() {
    if (process.env.REACT_APP_TRACKER_ID) {
      this.trackerId = process.env.REACT_APP_TRACKER_ID;
    }
    HostPageMessenger.on('setTracker', (tracker) => {
      this.trackerId = tracker;
    });

    HostPageMessenger.on('ready', () => HostPageMessenger.send('getTracker'));
    HostPageMessenger.on('userFeatures', (data: UserFeatures) =>  {
      this.userFeatures = data;
      this.permittedApps =
        data?.variants?.setting_permitted_ocp_apps
          ? data?.variants.setting_permitted_ocp_apps.split(',')
          : [];
    });
    HostPageMessenger.on('prismContext', (prismContext: PrismContext) => {
      this.prismContext = prismContext;
    });
    HostPageMessenger.on('accountDetails', (accountDetails: AccountDetails) => {
      this.accountDetails = accountDetails;
    });
  }

  public hasFeatureFlag = (flag: string) => {
    return this.userFeatures?.features?.[flag] || false;
  }

  public requiresAccountUpgrade = (appId: string) => {
    return this.permittedApps?.length !== 0 && !this.permittedApps?.includes(appId);
  }

  public getAccountType = () => {
    // using accountDetails if available, otherwise using prismContext
    // this could be removed when we fully switch to prismContext
    if (this.accountDetails) {
      return this.accountDetails.isOptiHub ? AccountType.HUB : AccountType.ODP;
    }
    return this.prismContext?.account.isOptiHub ? AccountType.HUB : AccountType.ODP;
  }
}

export const directoryStore = new DirectoryStore();
