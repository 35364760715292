import {Schema} from '@zaiusinc/app-forms-schema';
import {observer} from 'mobx-react';
import * as React from 'react';
import {Errors} from '../layout/Errors';
import classNames from 'classnames';
import styles from './OAuthImageButton.sass';
import {Tooltip} from '@blueprintjs/core';

interface Props {
  schema: Schema.OAuthImageButton;
  onClick: (action: string) => Promise<any>;
  disabled?: boolean;
  loading?: boolean;
  assetsBaseUrl?: string;
  errors?: string[];
}

@observer
export class OAuthImageButton extends React.Component<Props> {
  private button: React.RefObject<HTMLButtonElement> = React.createRef();
  private image: React.RefObject<HTMLImageElement> = React.createRef();

  public render() {
    const {
      errors,
      schema: {key}
    } = this.props;
    return (
      <div key={key} className="bp5-form-group">
        <div>{this.renderButton()}</div>
        <Errors errors={errors} />
      </div>
    );
  }

  public renderButton() {
    const {
      assetsBaseUrl,
      disabled,
      schema: {normalImage, pressedImage, focusImage, disabledImage, alternateText}
    } = this.props;
    return this.renderWithTooltip(
      <button
        ref={this.button}
        className={classNames(styles.button)}
        disabled={disabled}
        onClick={this.onClick}
        onMouseDown={() => this.renderImg(pressedImage)}
        onMouseUp={() => this.renderImg(normalImage)}
        onFocus={() => this.renderImg(focusImage)}
        onBlur={() => this.renderImg(normalImage)}
      >
        <img
          ref={this.image}
          alt={alternateText || 'Sign in'}
          src={`${assetsBaseUrl}/${disabled ? disabledImage : normalImage}`}
        />
      </button>
    );
  }

  private renderWithTooltip(jsx: JSX.Element) {
    const {help} = this.props.schema;
    if (help) {
      return (
        <Tooltip content={help} hoverOpenDelay={1000}>
          {jsx}
        </Tooltip>
      );
    }
    return jsx;
  }

  private renderImg = (image: string) => {
    if (this.image.current) {
      this.image.current.src = `${this.props.assetsBaseUrl}/${image}`;
    }
  };

  private onClick = async () => {
    try {
      await this.props.onClick('oauth');
    } catch (e) {
      console.error(e);
    }
  };
}
