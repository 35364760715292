import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import styles from './DataSyncFormSection.module.sass';
import { DropDown, Label, SelectItem } from '@zaiusinc/hera';
import { OptimizelyProduct, Source } from '../../../../types/DataSyncItem';
import { useObserver } from 'mobx-react-lite';
import Notification from '../Notification';
import { ValidateHandle } from './ValidateHandle';
import { DataSyncObjectsStore } from '../../../../stores/DataSyncObjectsStore';
import DataSyncProductsStore from '../../../../stores/DataSyncProductsStore';

export interface DataSyncFormSourceProps {
  initialValue: Source;
  onChange: (value: Source) => void;
  dataSyncObjectsStore: DataSyncObjectsStore | null;
}

const DataSyncFormSource = forwardRef<ValidateHandle, DataSyncFormSourceProps>((props, ref) => {
  const {initialValue, onChange, dataSyncObjectsStore} = props;
  const [source, setSource] = React.useState<Source>({...initialValue});
  const [errors, setErrors] = React.useState<{ [key: string]: boolean }>({
    object_name: false,
  });

  const [dataSyncProductsStore, setDataSyncProductsStore] = useState<DataSyncProductsStore | null>(null);

  useImperativeHandle(ref, () => ({
    validate: () => {
      if (source.product === OptimizelyProduct.HUB) {
        setErrors({
          product: !source.product,
          instance: false,
          object: !source.object.object_name,
        });

        return !!(source.product && source.object.object_name);
      } else {
        setErrors({
          product: !source.product,
          instance: !source.instance.id,
          object: !source.object.object_name,
        });

        return !!(source.product && source.instance.id && source.object.object_name);
      }
    },
  }));

  useEffect(() => {
    setDataSyncProductsStore(new DataSyncProductsStore());
  }, []);

  useEffect(() => {
    if (source?.instance?.id && dataSyncObjectsStore) {
      dataSyncObjectsStore.setInstanceId(source.instance.id);
    }
  }, [source?.instance?.id, dataSyncObjectsStore]);

  const productList = (): SelectItem[] => {
    return dataSyncProductsStore?.getSourceProductList().map((product) => {
      return {
        text: product.text,
        value: product.value,
        icon: product.iconPath ?
          (<img height={30} width={30} src={product.iconPath} alt={product.text}/>) : undefined,
        };
      }) || [];
  };

  const objectList = (): SelectItem[] => {
    const items = dataSyncObjectsStore?.getObjectList();
    if (!items) {
      return [];
    }

    return items.map((object) => {
      const icon =
        object.object_icon_url ? (<img height={16} src={object.object_icon_url} alt={object.object_display_name}/>) :
          (source.product === OptimizelyProduct.HUB ?
            (<img height={30} width={30} src={'/assets/data_platform.svg'} alt={object.object_display_name}/>) :
              undefined);

      return {
        text: object.object_display_name,
        value: object.object_name,
        icon,
      };
    });
  };

  return useObserver(() => (
    <div className={styles.container}>
      <Label label="Source" subLabel="Select a source of the data.">
        <DropDown
          initialContent="Select Product"
          items={productList()}
          loading={dataSyncProductsStore?.loading || false}
          onItemSelect={(item: any) => {
            const newData = {...source, product: item.value};
            if (source.product !== item.value) {
              newData.instance.id = '';
              newData.instance.name = '';
            }
            setSource(newData);
            onChange(newData);
            setErrors({...errors, product: false});
          }}
          value={source.product}
          width={390}/>
          <Notification message="Please select a product" show={errors.product} type="error"/>
      </Label>

      {source.product === 'CMP' &&
      <Label label="Instance">
          <DropDown
            initialContent="Select Instance"
            items={dataSyncProductsStore?.getInstanceList(source.product) || []}
            loading={dataSyncProductsStore?.loading || false}
            onItemSelect={function noRefCheck(item: any) {
              const newData = {...source};
              newData.instance = {id: item.value, name: item.text};
              setSource(newData);
              onChange(newData);
              setErrors({...errors, instance: false});
            }}
            value={source.instance.id}
            width={390}/>
          <Notification message="Please select an instance" show={errors.instance} type="error"/>
        </Label>}

      <Label label="Object">
        <DropDown
          filterable
          loading={dataSyncObjectsStore?.loading || false}
          initialContent="Select Object"
          value={source.object.object_name}
          items={objectList()}
          width={390}
          onItemSelect={(item) => {
            const newSource = {...source,
              object: {
                ...source.object,
                object_name: item.value,
                object_display_name: item.text
              }
            };
            setSource(newSource);
            onChange(newSource);
            setErrors({...errors, object_name: false});
          }}/>
        <Notification message="Please select an object" show={errors.object_name} type="error"/>
      </Label>

    </div>
  ));
});

export default DataSyncFormSource;
