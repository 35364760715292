import { Button, ButtonGroup, Icon, Intent, Menu, MenuItem, Popover } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Accordion, DropDown, SearchInput, SelectItem } from '@zaiusinc/hera';
import { useObserver } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import { showToasts } from '../../../lib/toaster';
import { ZipApi } from '../../../lib/ZipApi';
import { dataSyncStore } from '../../../stores/DataSyncStore';
import { directoryStore } from '../../../stores/DirectoryStore';
import { DataSyncItem, SyncStrategy } from '../../../types/DataSyncItem';
import styles from './DataSyncList.module.sass';
import { DataSyncExecution, DataSyncExecutionStatus } from '../../../types/DataSyncExecution';
import DataSyncIcons from './DataSyncIcons';
import { SegmentAnalytics } from '../../../lib/SegmentAnalytics';
import { DataSyncStrategyProvider } from '../DataSyncStrategyProvider';

const filterByOptions: SelectItem[] = [
  { text: 'All', value: 'all'},
  { text: 'Active', value: 'active'},
  { text: 'Paused', value: 'paused'},
];

const sortByOptions: SelectItem[] = [
  { text: 'Last Sync', value: 'lastExecution.created_at'},
  { text: 'Name', value: 'name'},
  { text: 'Source', value: 'source.object_name'},
  { text: 'Destination', value: 'destination.object_name'},
  { text: 'Product', value: 'destination.product'},
];

const DataSyncList = () => {
  const history = useHistory();

  useEffect(() => {
    if (directoryStore.trackerId) {
      dataSyncStore.fetch()
        .catch((e) => {
          showToasts([{intent: 'danger', message: `Failed to fetch data syncs. ${e}`}]);
        });
    }
  }, []);

  return useObserver(() => {
    const renderEmptyState = () => (
      <div className={styles.emptyDataSyncList}>
        <Icon icon={IconNames.SEARCH} iconSize={64}/>
        <p>No data syncs were found</p>
      </div>
    );

    const renderMenu = (data: DataSyncItem) => (
      <Menu className={styles.menu}>
        <MenuItem htmlTitle="menu-item" text="Executions"
                  onClick={() => history.push(`/data_syncs/${data.id}/executions`)}/>
            <MenuItem htmlTitle="menu-item" text="Trigger now" onClick={() => handleTriggerSync(data)}/>
        {DataSyncStrategyProvider.getStrategy(data) === SyncStrategy.SCHEDULED &&
          <MenuItem
            htmlTitle="menu-item"
            text={data.enabled ? 'Pause' : 'Start'}
            onClick={() => handleToggleDataSync(data)}
          />
        }
        <MenuItem htmlTitle="menu-item" text="Edit" onClick={() => history.push(`/data_syncs/${data.id}`)}/>
        <MenuItem htmlTitle="menu-item" text="Delete" onClick={() => handleDeleteDataSync(data)}/>
      </Menu>
    );

    const handleToggleDataSync = async (dataSync: DataSyncItem) => {
      await SegmentAnalytics.track('Data Sync Action', {
        action: dataSync.enabled ? 'Pause' : 'Start',
        path: window.location.pathname + window.location.search,
        dataSyncId: dataSync.id
      });
      try {
        await dataSyncStore.toggleDataSync(dataSync.id);
      } catch (error) {
        showToasts([{intent: 'warning', message: `Cannot start/pause data sync. ${error}`}]);
      }
    };

    const handleDeleteDataSync = async (dataSync: DataSyncItem) => {
      await SegmentAnalytics.track('Data Sync Action', {
        action: 'Delete',
        path: window.location.pathname + window.location.search,
        dataSyncId: dataSync.id
      });

      try {
        await dataSyncStore.deleteDataSync(dataSync.id);
        showToasts([{intent: 'success', message: 'Data sync deleted successfully'}]);
      } catch (error) {
        showToasts([{intent: 'warning', message: `Cannot delete data sync. ${error}`}]);
      }
    };

    const handleTriggerSync = async (dataSync: DataSyncItem) => {
      await SegmentAnalytics.track('Data Sync Action', {
        action: 'Trigger',
        path: window.location.pathname + window.location.search,
        dataSyncId: dataSync.id
      });

      if (await ZipApi.triggerSync(dataSync.id)) {
        showToasts([{intent: 'success', message: 'Sync triggered successfully'}]);
      } else {
        showToasts([{intent: 'warning', message: 'Failed to trigger sync'}]);
      }
    };

    const renderDataSyncCardHeader = (data: DataSyncItem) => (
      <div key={`${data.id}-head`} className={styles.cardHeader}>
        <div className={styles.cardTitle}>
          <DataSyncIcons dataSync={data} />
          <span>{data.name}</span>
        </div>
        <div className={styles.cardLastStatus}>
        {DataSyncStrategyProvider.getStrategy(data) === SyncStrategy.SCHEDULED && getLastSyncStatus(data)}
          <div onClick={(e) => e.stopPropagation()}>
            <Popover content={renderMenu(data)} placement="bottom">
              <button className={styles.cardEllipsis}>...</button>
            </Popover>
          </div>
        </div>
      </div>
    );

    const getLastSyncStatus = (data: DataSyncItem) => {
      const lastExecution = data.lastExecution;
      const activeIcon = data.enabled ?
        <Icon icon={IconNames.HISTORY}/> :
        <Icon icon={IconNames.PAUSE}/>;

      const activeLabel = <span style={{width: '71px'}}>
        <b>{data.enabled ? 'Active' : 'Paused'}</b>
      </span>;

      if (!lastExecution) {
        return <div className={styles.cardStatusMessage}>
          {activeIcon}
          {activeLabel}
          <Icon icon={IconNames.FULL_CIRCLE} intent={Intent.WARNING}/>
          <span>It has never been executed</span>
        </div>;
      }

      let healthIcon: React.ReactNode;
      let healthLabel: string;
      const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
      };
      const formattedDate = new Date(lastExecution.created_at).toLocaleString('en-US', options);
      switch (lastExecution.status) {
        case DataSyncExecutionStatus.COMPLETED:
          healthIcon = <Icon icon={IconNames.FULL_CIRCLE} intent={Intent.SUCCESS}/>;
          healthLabel = `Last synced on ${formattedDate}`;
          break;
        case DataSyncExecutionStatus.FAILED:
          healthIcon = <Icon icon={IconNames.ERROR} intent={Intent.DANGER}/>;
          healthLabel = `Last synced on ${formattedDate}`;
          break;
        case DataSyncExecutionStatus.IN_PROGRESS:
          healthIcon = <Icon icon={IconNames.REFRESH} intent={Intent.PRIMARY}/>;
          healthLabel = 'Syncing...';
          break;
        default:
          healthIcon = <Icon icon={IconNames.FULL_CIRCLE} intent={Intent.NONE}/>;
          healthLabel = `Last synced on ${formattedDate}`;
          break;
      }
      return <div className={styles.cardStatusMessage}>
        {activeIcon}
        {activeLabel}
        {healthIcon}
        <span>{healthLabel}</span>
      </div>;
    };

    const renderItemsNumber = (execution?: DataSyncExecution) => {
      if (!execution) {
        return <p>No stats yet</p>;
      }

      if (execution.itemsImported === undefined && execution.itemsDropped === undefined) {
        return <p>No stats available</p>;
      }
      return (
        <>
          {execution.itemsImported !== undefined ?
            (<>Items Imported: <b>{execution.itemsImported}</b></>) : (<>Items</>)}
          {execution.itemsDropped !== undefined  && <> Dropped: <b>{execution.itemsDropped}</b></>}
        </>
      );
    };

    const renderDataSyncCard = (data: DataSyncItem) => (
      <Accordion
        key={data.id}
        header={renderDataSyncCardHeader(data)}
        startOpen={false}
        minimal={true}
        className={styles.card}
      >
        <div className={styles.cardDetails}>
          <p>
            {data.source.object.object_display_name || data.source.object.object_name}
            <Icon icon={IconNames.CARET_RIGHT}/>
            {data.destination.object.object_name}
          </p>
          {DataSyncStrategyProvider.getStrategy(data) === SyncStrategy.SCHEDULED &&
            <table className={styles.carDetailsTable}>
              <thead>
              <tr>
                <th>TOTAL SYNC</th>
                <th className={styles.carDetailsTableError}>LAST ERROR</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>{renderItemsNumber(data.lastExecution)}</td>
                <td>
                  {data.lastExecution?.status === 'FAILED' ?
                    <div className={styles.lastError}>{data.lastExecution?.message}</div> :
                    <div>None</div>}
                </td>
              </tr>
              </tbody>
          </table>}
        </div>
      </Accordion>
    );

    if (dataSyncStore.loading) {
      return <LoadingIndicator/>;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.listTop}>
          <SearchInput
            className={styles.searchInput}
            onChange={(value) => dataSyncStore.searchFilter = value.trim()}
            placeholder="Find Data Sync"
            searchTerm={dataSyncStore.searchFilter}
          />
          <div className={styles.filtersSection}>
            <span className={styles.filter}>Filter by</span>
            <DropDown
              items={filterByOptions}
              value={dataSyncStore.filterByStatus}
              onItemSelect={(status) => dataSyncStore.filterByStatus = status.value}
            />
            <span style={{width: '20px'}}></span>
            <span className={styles.filter}>Sort by</span>
            <ButtonGroup>
              <Button
                icon={dataSyncStore.sortDirection ? 'sort-desc' : 'sort-asc'}
                onClick={() => dataSyncStore.sortDirection = dataSyncStore.sortDirection ? 0 : 1}
              />
              <DropDown
                items={sortByOptions}
                initialContent="Last Sync"
                value={dataSyncStore.sortBy}
                onItemSelect={(status) => dataSyncStore.sortBy = status.value}
              />
            </ButtonGroup>
          </div>
        </div>
        {dataSyncStore.dataSyncs.length === 0 ? renderEmptyState() : dataSyncStore.dataSyncs.map(renderDataSyncCard)}
      </div>
    );
  });
};

export default DataSyncList;
