import { observable } from 'mobx';
import { ObjectDefinition, ObjectFieldDefinition } from '../types/DataSyncItem';

export abstract class DataSyncObjectsStore {

  @observable public loading = false;

  public abstract setInstanceId(instanceId: string): void;
  public abstract getObjectList(): ObjectDefinition[];
  public abstract getObjectFields(objectName: string): ObjectFieldDefinition[];
}
