import React from 'react';
import { dataSyncStore } from '../../stores/DataSyncStore';
import DataSyncForm from './components/DataSyncForm';
import { useHistory } from 'react-router-dom';
import { DataSyncItem } from '../../types/DataSyncItem';
import { showToasts } from '../../lib/toaster';
import { useObserver } from 'mobx-react-lite';
import { SegmentAnalytics } from '../../lib/SegmentAnalytics';

const CreateDataSyncPage = () => {
  const dataSync = DataSyncItem.createEmpty();
  const history = useHistory();

  const onSubmit = async (syncObj: any) => {
    await SegmentAnalytics.track('Data Sync Action', {
      action: 'Create',
      path: window.location.pathname + window.location.search,
    });
    dataSyncStore.createDataSync(syncObj)
      .then(() => {
        history.push('/data_syncs');
        showToasts([{intent: 'success', message: 'Data sync created successfully'}]);
      })
      .catch((e) => showToasts([{intent: 'warning', message: `Failed to create data sync. ${e}`}]));
  };

  const onCancel = () => {
    history.push('/data_syncs');
  };

  return useObserver(() => (
    <DataSyncForm mode="create" initialValues={dataSync} onSubmit={onSubmit} onCancel={onCancel}/>
  ));
};

export default CreateDataSyncPage;
