import {ElementType, Schema} from '@zaiusinc/app-forms-schema';
import {Accordion, Badge} from '@zaiusinc/hera';
import {observable} from 'mobx';
import {observer} from 'mobx-react';
import * as React from 'react';
import {ButtonRenderer} from '../layout/ButtonRenderer';
import {evaluateAttributes} from '../lib/Attributes';
import {PredicateEvaluator} from '../lib/PredicateEvaluator';
import {DataStore} from '../stores/DataStore';
import {Button} from './Button';
import {Divider} from './Divider';
import {Instructions} from './Instructions';
import {MultiSelectField} from './MultiSelectField';
import {OAuthButton} from './OAuthButton';
import {SecretField} from './SecretField';
import styles from './Section.sass';
import {SelectField} from './SelectField';
import {TextField} from './TextField';
import {Toggle} from './Toggle';
import {OAuthImageButton} from './OAuthImageButton';

const INPUT_ELEMENT_TYPES = [
  ElementType.File,
  ElementType.MultiSelect,
  ElementType.Secret,
  ElementType.Select,
  ElementType.Text,
  ElementType.Toggle
];

export interface Props {
  section: Schema.Section;
  data: DataStore;
  errors: Schema.FormErrors['sections']['section'];
  isOpen?: boolean;
  onClick: (s: Schema.Section) => void;
  onFetchRemoteData: (
    section: string,
    field: string,
    source: Schema.DataSource,
    data: Schema.FormSectionData
  ) => Promise<Schema.SelectOption[]>;
  onAction: (section: Schema.Section, action: string) => Promise<boolean>;
}

@observer
export class Section extends React.Component<Props> {
  @observable private awaitingAction = false;

  public render() {
    const {section, isOpen, data, errors} = this.props;
    const disabled = new PredicateEvaluator(section.key, data).evaluate(section.disabled);
    const errorCount = Object.keys(errors).length;
    const badge =
      data.options.showErrorBadge && errorCount > 0 ? (
        <Badge badge={Object.keys(errors).length} intent="danger" />
      ) : undefined;

    return (
      <Accordion
        className={styles.section}
        minimal={data.options.minimalAccordions}
        disabled={disabled}
        isOpen={isOpen && !disabled}
        header={section.label}
        onClick={this.onSectionClick}
        rightIcon={badge}
      >
        {isOpen || isOpen === undefined ? this.renderElements() : null}
      </Accordion>
    );
  }

  private renderElements() {
    const {
      errors,
      data,
      section: {key: section}
    } = this.props;

    const buttonRenderer = new ButtonRenderer();
    const flushButtonsIfNecessary = (index: number, key: string) => {
      if (buttonRenderer.hasButtons) {
        const nextElement = elements[index + 1];
        if (!nextElement || (nextElement.type !== ElementType.Button && nextElement.type !== ElementType.LinkButton)) {
          return buttonRenderer.flush(key);
        }
      }
      return null;
    };

    const elements = this.getElements();
    return elements.map((schema, index) => {
      const key = schema.key || `__${index}`;
      const value = schema.key ? data.get(section, schema.key) : undefined;
      const attributes = {
        key,
        value,
        ...evaluateAttributes(section, schema, data),
        loading: data.loading,
        assetsBaseUrl: data.assetsBaseUrl
      };

      // override disabled state during an action
      if (this.awaitingAction || data.loading) {
        attributes.disabled = true;
      }

      if (attributes.visible === false) {
        return flushButtonsIfNecessary(index, key);
      }

      switch (schema.type) {
        case Schema.ElementType.Button:
        case Schema.ElementType.LinkButton:
          buttonRenderer.addButton(
            <Button {...attributes} schema={schema} onClick={this.onButtonClick} />,
            errors[schema.key!]
          );
          return flushButtonsIfNecessary(index, key);

        case Schema.ElementType.Text:
          return <TextField {...attributes} schema={schema} errors={errors[schema.key]} onChange={this.onChange} />;

        case Schema.ElementType.Secret:
          return <SecretField {...attributes} schema={schema} errors={errors[schema.key]} onChange={this.onChange} />;

        case Schema.ElementType.Toggle:
          return <Toggle {...attributes} schema={schema} errors={errors[schema.key]} onChange={this.onChange} />;

        case Schema.ElementType.Select:
          return (
            <SelectField
              {...attributes}
              schema={schema}
              errors={errors[schema.key]}
              onChange={this.onChange}
              onChangeAction={this.onChangeAction}
              onFetchRemoteData={this.onFetchRemoteData}
            />
          );

        case Schema.ElementType.MultiSelect:
          return (
            <MultiSelectField
              {...attributes}
              schema={schema}
              errors={errors[schema.key]}
              onChange={this.onChange}
              onChangeAction={this.onChangeAction}
              onFetchRemoteData={this.onFetchRemoteData}
            />
          );

        case Schema.ElementType.Instructions:
          return <Instructions {...attributes} schema={schema} />;

        case Schema.ElementType.Divider:
          return <Divider {...attributes} schema={schema} />;

        case Schema.ElementType.OAuthButton:
          return (
            <OAuthButton
              {...attributes}
              schema={schema}
              errors={errors[schema.key!]}
              onClick={this.onButtonClick}
              assetsBaseUrl={data.assetsBaseUrl}
            />
          );

        case Schema.ElementType.OAuthImageButton:
          return (
            <OAuthImageButton
              {...attributes}
              schema={schema}
              errors={errors[schema.key!]}
              onClick={this.onButtonClick}
              assetsBaseUrl={data.assetsBaseUrl}
            />
          );

        default:
          return null;
      }
    });
  }

  private onSectionClick = () => {
    this.props.onClick(this.props.section);
  };

  private onButtonClick = (action: string) => {
    this.awaitingAction = true;
    return this.props
      .onAction(this.props.section, action)
      .then((result) => {
        this.awaitingAction = false;
        return result;
      })
      .catch(() => (this.awaitingAction = false));
  };

  private onChange = (field: string, value: Schema.FormValue) => {
    this.props.data.update(this.props.section.key, field, value);
  };

  private onChangeAction = (action: string) => {
    this.awaitingAction = true;
    this.props
      .onAction(this.props.section, action)
      .then(() => (this.awaitingAction = false))
      .catch(() => (this.awaitingAction = false));
  };

  private onFetchRemoteData = (field: string, dataSource: Schema.DataSource): Promise<Schema.SelectOption[]> => {
    const section = this.props.section.key;
    return this.props.onFetchRemoteData(section, field, dataSource, this.props.data.getSection(this.props.section));
  };

  private getElements(): Schema.AnyElement[] {
    const {section, data} = this.props;
    if (!data.options.allowButtons) {
      return section.elements.filter((el) => el.type !== ElementType.Button);
    }

    // if buttons are allowed, ensure there is at least one button per section (a save button)
    if (!this.containsButtons() && this.containsActiveInputs()) {
      return [
        ...section.elements,
        {
          type: Schema.ElementType.Button,
          label: 'Save',
          action: 'save',
          style: 'primary'
        }
      ];
    }
    return section.elements;
  }

  private containsButtons(): boolean {
    return this.props.section.elements.some(
      (e) => e.type === Schema.ElementType.Button || e.type === Schema.ElementType.OAuthButton
    );
  }

  private containsActiveInputs(): boolean {
    const {
      data,
      section: {key: section}
    } = this.props;
    return this.props.section.elements.some((schema) => {
      if (INPUT_ELEMENT_TYPES.includes(schema.type)) {
        const attributes = evaluateAttributes(section, schema, data);
        return attributes.visible && !attributes.disabled;
      }
      return false;
    });
  }
}
